import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faBars,
	faCalendarDays,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faEnvelope,
	faGlobe,
	faLocationDot,
	faPhone,
	faRulerCombined,
	faTimes,
	faUsers,
} from '@fortawesome/pro-light-svg-icons';

import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faArrows,
	faBedAlt,
	faBellConcierge,
	faBicycle,
	faBuildingColumns,
	faCar,
	faCheck,
	faCoffeePot,
	faCocktail,
	faCouch,
	faCroissant,
	faEuroSign,
	faGift,
	faGolfFlagHole,
	faHeart,
	faMapLocationDot,
	faMasksTheater,
	faMinus,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faPlay,
	faPlus,
	faQuoteLeft,
	faSearch,
	faSpa,
	faStar,
	faStarHalf,
	faStars,
	faStore,
	faSwimmingPool,
	faUtensils,
	faWineGlass,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrows,
	faBars,
	faBedAlt,
	faBellConcierge,
	faBicycle,
	faBuildingColumns,
	faCar,
	faCalendarDays,
	faCheck,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faCocktail,
	faCoffeePot,
	faCouch,
	faCroissant,
	faEnvelope,
	faEuroSign,
	faFacebook,
	faGlobe,
	faGift,
	faGolfFlagHole,
	faHeart,
	faMapLocationDot,
	faMasksTheater,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faInstagram,
	faLinkedin,
	faLocationDot,
	faMinus,
	faPhone,
	faPinterest,
	faPlay,
	faPlus,
	faQuoteLeft,
	faRulerCombined,
	faSearch,
	faSoundcloud,
	faSpotify,
	faStar,
	faStarHalf,
	faStars,
	faSpa,
	faStore,
	faSwimmingPool,
	faTiktok,
	faTimes,
	faTwitter,
	faUsers,
	faUsers,
	faUtensils,
	faWhatsapp,
	faWineGlass,
	faXTwitter,
	faYoutube,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
