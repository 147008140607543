import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactoRHWqeZDcrMeta } from "/vercel/path0/pages/[hotel]/contact.vue?macro=true";
import { default as galleryN76wK94GGgMeta } from "/vercel/path0/pages/[hotel]/gallery.vue?macro=true";
import { default as highlightspo7DXMM2rvMeta } from "/vercel/path0/pages/[hotel]/highlights.vue?macro=true";
import { default as _91item_93MkAvq44pMvMeta } from "/vercel/path0/pages/[hotel]/packages/[item].vue?macro=true";
import { default as index5EomVuVsVxMeta } from "/vercel/path0/pages/[hotel]/packages/index.vue?macro=true";
import { default as reviewsEWiTBhXASYMeta } from "/vercel/path0/pages/[hotel]/reviews.vue?macro=true";
import { default as roomsWLxFlY7wBBMeta } from "/vercel/path0/pages/[hotel]/rooms.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "hotel-contact___en",
    path: "/:hotel()/contact",
    component: () => import("/vercel/path0/pages/[hotel]/contact.vue")
  },
  {
    name: "hotel-contact___fr",
    path: "/fr/:hotel()/contact",
    component: () => import("/vercel/path0/pages/[hotel]/contact.vue")
  },
  {
    name: "hotel-contact___nl",
    path: "/nl/:hotel()/contact",
    component: () => import("/vercel/path0/pages/[hotel]/contact.vue")
  },
  {
    name: "hotel-contact___de",
    path: "/de/:hotel()/kontakt",
    component: () => import("/vercel/path0/pages/[hotel]/contact.vue")
  },
  {
    name: "hotel-gallery___en",
    path: "/:hotel()/gallery",
    component: () => import("/vercel/path0/pages/[hotel]/gallery.vue")
  },
  {
    name: "hotel-gallery___fr",
    path: "/fr/:hotel()/galerie",
    component: () => import("/vercel/path0/pages/[hotel]/gallery.vue")
  },
  {
    name: "hotel-gallery___nl",
    path: "/nl/:hotel()/galerij",
    component: () => import("/vercel/path0/pages/[hotel]/gallery.vue")
  },
  {
    name: "hotel-gallery___de",
    path: "/de/:hotel()/galerie",
    component: () => import("/vercel/path0/pages/[hotel]/gallery.vue")
  },
  {
    name: "hotel-highlights___en",
    path: "/:hotel()/highlights",
    component: () => import("/vercel/path0/pages/[hotel]/highlights.vue")
  },
  {
    name: "hotel-highlights___fr",
    path: "/fr/:hotel()/attractions",
    component: () => import("/vercel/path0/pages/[hotel]/highlights.vue")
  },
  {
    name: "hotel-highlights___nl",
    path: "/nl/:hotel()/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/[hotel]/highlights.vue")
  },
  {
    name: "hotel-highlights___de",
    path: "/de/:hotel()/sehenswurdigkeiten",
    component: () => import("/vercel/path0/pages/[hotel]/highlights.vue")
  },
  {
    name: "hotel-packages-item___en",
    path: "/:hotel()/special-offers/:item()",
    component: () => import("/vercel/path0/pages/[hotel]/packages/[item].vue")
  },
  {
    name: "hotel-packages-item___fr",
    path: "/fr/:hotel()/arrangements/:item()",
    component: () => import("/vercel/path0/pages/[hotel]/packages/[item].vue")
  },
  {
    name: "hotel-packages-item___nl",
    path: "/nl/:hotel()/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/[hotel]/packages/[item].vue")
  },
  {
    name: "hotel-packages-item___de",
    path: "/de/:hotel()/arrangements/:item()",
    component: () => import("/vercel/path0/pages/[hotel]/packages/[item].vue")
  },
  {
    name: "hotel-packages___en",
    path: "/:hotel()/special-offers",
    component: () => import("/vercel/path0/pages/[hotel]/packages/index.vue")
  },
  {
    name: "hotel-packages___fr",
    path: "/fr/:hotel()/arrangements",
    component: () => import("/vercel/path0/pages/[hotel]/packages/index.vue")
  },
  {
    name: "hotel-packages___nl",
    path: "/nl/:hotel()/arrangementen",
    component: () => import("/vercel/path0/pages/[hotel]/packages/index.vue")
  },
  {
    name: "hotel-packages___de",
    path: "/de/:hotel()/arrangements",
    component: () => import("/vercel/path0/pages/[hotel]/packages/index.vue")
  },
  {
    name: "hotel-reviews___en",
    path: "/:hotel()/reviews",
    component: () => import("/vercel/path0/pages/[hotel]/reviews.vue")
  },
  {
    name: "hotel-reviews___fr",
    path: "/fr/:hotel()/revues",
    component: () => import("/vercel/path0/pages/[hotel]/reviews.vue")
  },
  {
    name: "hotel-reviews___nl",
    path: "/nl/:hotel()/beoordelingen",
    component: () => import("/vercel/path0/pages/[hotel]/reviews.vue")
  },
  {
    name: "hotel-reviews___de",
    path: "/de/:hotel()/rezensionen",
    component: () => import("/vercel/path0/pages/[hotel]/reviews.vue")
  },
  {
    name: "hotel-rooms___en",
    path: "/:hotel()/rooms",
    component: () => import("/vercel/path0/pages/[hotel]/rooms.vue")
  },
  {
    name: "hotel-rooms___fr",
    path: "/fr/:hotel()/chambres",
    component: () => import("/vercel/path0/pages/[hotel]/rooms.vue")
  },
  {
    name: "hotel-rooms___nl",
    path: "/nl/:hotel()/kamers",
    component: () => import("/vercel/path0/pages/[hotel]/rooms.vue")
  },
  {
    name: "hotel-rooms___de",
    path: "/de/:hotel()/zimmer",
    component: () => import("/vercel/path0/pages/[hotel]/rooms.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/questions-frequemment-posees",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___nl",
    path: "/nl/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/haufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "news-item___en",
    path: "/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___fr",
    path: "/fr/actualites/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___nl",
    path: "/nl/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___de",
    path: "/de/nachrichten/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___fr",
    path: "/fr/actualites",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___nl",
    path: "/nl/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "privacy___en",
    path: "/cookie-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/cookie-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___nl",
    path: "/nl/cookie-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/cookie-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]